import React from 'react';

const Home = () => {
  return (
    <div className='space-y-4'>   
      <p>简易操作流程:</p>
      <p> 1. TG机器人 - 取得机器人邀请连结</p>
      <p> 2. 新增影片标签</p>
      <p> 3. 新增影片</p>
      <p> 4. 新增广告</p>
      <p>5. 绑定影片广告</p>
      <p>6. 发送排程</p>        
    </div>
  );
};

export default Home;