import React, { useContext, useState } from 'react';
import MenuContext from '../context/MenuContext';
import { CSSTransition } from 'react-transition-group';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

const Menu = () => {
  const { menuData, setMenuData, showMenu } = useContext(MenuContext);

  const menuClickHandler = (index) => {
    setMenuData(prevMenuData => {
      let newMenu = [...prevMenuData];
      newMenu[index].active = !newMenu[index].active;
      return newMenu;
    });
  }

  return (
    <>
      <div className='max-xl:hidden flex flex-col h-full min-w-48 bg-[#233272] text-stone-200 text-sm whitespace-nowrap'>
        {menuData.map((menu, index) => (
          <div key={menu.label} className=''>
            <div className=''>
              {menu.items ?
                <div
                  className='flex items-center w-full relative px-4 py-6 space-x-4 hover:bg-[#5c6797] cursor-pointer'
                  onClick={() => menuClickHandler(index)}
                >
                  <span className=''>{menu.icon}</span>
                  <span>{menu.label}</span>
                  {menu.items &&
                    <div className='absolute m-auto right-4'>{menu.active ? <DownOutlined /> : <RightOutlined />}</div>
                  }
                </div>
                :
                <a href={`${menu.url}`}>
                  <div
                    className='flex items-center w-full relative p-4 py-6 space-x-4 hover:bg-[#5c6797]'
                    onClick={() => menuClickHandler(index)}
                  >
                    <span className=''>{menu.icon}</span>
                    <span>{menu.label}</span>
                    {menu.items &&
                      <div className='absolute m-auto right-4'>{menu.active ? <DownOutlined /> : <RightOutlined />}</div>
                    }
                  </div>
                </a>
              }

              <CSSTransition
                in={menu.active}
                timeout={300}
                classNames="menu-slide"
                unmountOnExit
              >
                <div>
                  <div>
                    
                  
                  {menu.items && menu.items.map(item => (
                    <a key={item.label} href={`${item.url}`}>
                      <div className='flex flex-col w-full pl-14 py-4 hover:bg-[#35427c]'>
                        <div className='flex flex-col'>
                          <div>
                            <span className='w-4'></span>{item.label}
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
                  </div>
                </div>
              </CSSTransition>
            </div>
          </div>
        ))}
      </div>

      {/* 手機版 */}
      {showMenu &&
        <div className='absolute z-10 min-xl:hidden mt-14 flex flex-col h-[calc(100%-3.5rem)] min-w-48 bg-[#233272] text-stone-200 text-xs whitespace-nowrap'>
          {menuData.map((menu, index) => (
            <div key={menu.label} className=''>
              <div className=''>
                {menu.items ?
                  <div
                    className='flex items-center w-full relative p-4 space-x-4 hover:bg-[#5c6797] cursor-pointer'
                    onClick={() => menuClickHandler(index)}
                  >
                    <span className=''>{menu.icon}</span>
                    <span>{menu.label}</span>
                    {menu.items &&
                      <div className='absolute m-auto right-4'>{menu.active ? <DownOutlined /> : <RightOutlined />}</div>
                    }
                  </div>
                  :
                  <a href={`${menu.url}`}>
                    <div
                      className='flex items-center w-full relative p-4 space-x-4 hover:bg-[#5c6797]'
                      onClick={() => menuClickHandler(index)}
                    >
                      <span className=''>{menu.icon}</span>
                      <span>{menu.label}</span>
                      {menu.items &&
                        <div className='absolute m-auto right-4'>{menu.active ? <DownOutlined /> : <RightOutlined />}</div>
                      }
                    </div>
                  </a>
                }

                <CSSTransition
                  in={menu.active}
                  timeout={300}
                  classNames="menu-slide"
                  unmountOnExit
                >
                  <div>
                    {menu.items && menu.items.map(item => (
                      <a key={item.label} href={`${item.url}`}>
                        <div className='flex flex-col w-full pl-14 py-4 hover:bg-[#35427c]'>
                          <div className='flex flex-col'>
                            <div>
                              <span className='w-4'></span>{item.label}
                            </div>
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                </CSSTransition>
              </div>
            </div>
          ))}
        </div>
      }
    </>
  );
};

export default Menu;