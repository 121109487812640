import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { tag_manage } from '../../api/api';
import ToastContext from '../../context/ToastContext';
import LoadingContext from '../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import Input from 'antd/es/input/Input';

const TagEdit = (props) => {

  const {showTagEdit, setShowTagEdit, tableRowData} = props
  const {showToast, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()

  const formik = useFormik({
    initialValues: {
      name: tableRowData.team_name
    },

   validate: (data) => {
      let errors = {};      
     
      if (!data.name) {								
        errors.name = '必填';
      }else if(data.name.length > 20){
        errors.name = "不可大於30字"
      }     

      return errors;
    },
    onSubmit: async(data) => {  
      
      const filterData = {     
        tag: data.name,   
        action: `e-${tableRowData.id}`    
      };    

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await tag_manage(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setIsRefresh(prev => !prev)                     
          showToast("success", "" + status, statusText)         
          setShowTagEdit(false)  
        } else {
          showToast("error", "" + status, statusText)
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }   
  })

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    
  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error text-red-500">{formik.errors[name]}</small> : <small className="p-error text-red-500">&nbsp;</small>;
  };	   


  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Modal
          title="编辑标签"
          centered
          open={showTagEdit}
          onCancel={() => setShowTagEdit(false)}
          footer={[                        
            <Button key="1" onClick={() => setShowTagEdit(false)}>取消</Button>,
            <Button key="2" type="primary" onClick={formik.handleSubmit}>送出</Button>
          ]}
          width={500}
          
        >
          <div className='flex flex-col'>
            <label htmlFor="name">标签名称</label>
            <Input
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}             
            />          
            {getFormErrorMessage("name")}
          </div>          
        </Modal>
      </form>
    </div>
  );
};

export default TagEdit;