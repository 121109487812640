import { TOTP } from "otpauth";

export const API_URL = "https://tami9527.stkcpu.cc";
export const TOTP_SECRET = "JBSWY3DPEHPK3PXP";

function getTOTP() {
  const totp = new TOTP({    
    period: 30,
    secret: TOTP_SECRET
  });

  return totp.generate()
}

export async function get_tg_invite_link() {

  // filterData = convertNumbersToStrings(filterData)
  
  const totp_password = getTOTP()

  const body = new URLSearchParams({totp_password})

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${API_URL}/get_tg_invite_link`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body.toString()
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status };
    }else {
      return {status: response.status, statusText: typeof(listResponse) == "string" ? listResponse : "系统预设错误"}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function tag_find() {

  // filterData = convertNumbersToStrings(filterData)
  
  const totp_password = getTOTP()

  // 使用 URLSearchParams 将数据转换为 x-www-form-urlencoded 格式
  const body = new URLSearchParams({totp_password});   

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${API_URL}/tag_find`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body.toString(),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status, statusText: response.message };
    }else {
      return {status: response.status, statusText: response.error || response.message}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function tag_manage(filterData) {

  // filterData = convertNumbersToStrings(filterData)
  const totp_password = getTOTP()

  const { action, tag } = filterData;
  // 使用 URLSearchParams 将数据转换为 x-www-form-urlencoded 格式
  const body = new URLSearchParams({totp_password, action, tag}); 

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${API_URL}/tag_manage`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body.toString(),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status, statusText: listResponse.message };
    }else {
      console.log(listResponse);
      return {status: response.status, statusText: listResponse.error || listResponse.message}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function video_id_find(filterData) {

  // filterData = convertNumbersToStrings(filterData)

  const totp_password = getTOTP()
  
  const { state_type } = filterData;

  const body = new URLSearchParams({totp_password, state_type});

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${API_URL}/video_id_find`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body.toString(),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status, statusText: listResponse.message };
    }else {
      console.log(listResponse);
      return {status: response.status, statusText: listResponse.error || listResponse.message}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function video_title_edit(filterData) {

  // filterData = convertNumbersToStrings(filterData)

  const totp_password = getTOTP()
  
  const { video_id, new_title } = filterData;

  const body = new URLSearchParams({ totp_password, video_id, new_title });

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${API_URL}/video_title_edit`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body.toString(),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status, statusText: listResponse.message };
    }else {
      console.log(listResponse);
      return {status: response.status, statusText: listResponse.error || listResponse.message}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function ad_find(filterData) {

  // filterData = convertNumbersToStrings(filterData)

  const totp_password = getTOTP()  

  const body = new URLSearchParams({ totp_password });

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${API_URL}/ad_find`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body.toString(),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status, statusText: listResponse.message };
    }else {
      console.log(listResponse);
      return {status: response.status, statusText: listResponse.error || listResponse.message}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function ad_add(filterData) {

  // filterData = convertNumbersToStrings(filterData)

  const totp_password = getTOTP()  

  const {files, remark_user} = filterData

  console.log("api files: ", files);

  const formData = new FormData();
  formData.append('totp_password', totp_password);
  formData.append('remark_user', remark_user); 
  formData.append('files', files); 

  console.log("formData: ", formData.get("files"));

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${API_URL}/ad_add`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
      },
      body: formData,
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status, statusText: listResponse.message };
    }else {
      console.log(listResponse);
      return {status: response.status, statusText: listResponse.error || listResponse.message}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function send_permissions() {

  // filterData = convertNumbersToStrings(filterData)

  const totp_password = getTOTP()   

  const body = new URLSearchParams({ totp_password });

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${API_URL}/send_permissions`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body.toString(),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status, statusText: listResponse.message };
    }else {
      console.log(listResponse);
      return {status: response.status, statusText: listResponse.error || listResponse.message}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function ad_assign(filterData) {

  // filterData = convertNumbersToStrings(filterData)

  const totp_password = getTOTP()   

  const {video_ids, ad_start, ad_middle, ad_end} = filterData

  const body = new URLSearchParams({ totp_password, video_ids, ad_start, ad_middle, ad_end });

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${API_URL}/ad_assign`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body.toString(),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status, statusText: listResponse.message };
    }else {
      console.log(listResponse);
      return {status: response.status, statusText: listResponse.error || listResponse.message}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function send_set_time(filterData) {

  // filterData = convertNumbersToStrings(filterData)

  const totp_password = getTOTP()   

  const {values, tg_id, send_time} = filterData

  const body = new URLSearchParams({ totp_password, values, tg_id, send_time });

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${API_URL}/send_set_time`, {
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body.toString(),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status, statusText: listResponse.message };
    }else {
      console.log(listResponse);
      return {status: response.status, statusText: listResponse.error || listResponse.message}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function video_add(filterData) {

  // filterData = convertNumbersToStrings(filterData)

  const totp_password = getTOTP()   

  const { tag, video1, image1, title1, video2, image2, title2, video3, image3, title3, video4, image4, title4, video5, image5, title5 } = filterData

  const body = new URLSearchParams({ totp_password, tag, video1, image1, title1, video2, image2, title2, video3, image3, title3, video4, image4, title4, video5, image5, title5 });

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${API_URL}/video_add`, { 
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body.toString(),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status, statusText: listResponse.message };
    }else {
      console.log(listResponse);
      return {status: response.status, statusText: listResponse.error || listResponse.message}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}

export async function send_list() {

  // filterData = convertNumbersToStrings(filterData)

  const totp_password = getTOTP()    

  const body = new URLSearchParams({ totp_password });

  try {
    const controller = new AbortController();
    const time = setTimeout(() => controller.abort(), 30000);

    const response = await fetch(`${API_URL}/send_list`, { 
      method: "POST",
      signal: controller.signal,
      headers: {
        "accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body.toString(),
    });
    clearTimeout(time);
    const listResponse = await response.json();
    if (response.ok) {      
      return { listResponse: listResponse, status: response.status, statusText: listResponse.message };
    }else {
      console.log(listResponse);
      return {status: response.status, statusText: listResponse.error || listResponse.message}  
    }
  } catch (error) {
    console.log(error)
    return error;
  }
}