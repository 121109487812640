import React, { useContext, useEffect, useState, useRef } from 'react';
import LoadingContext from '../../context/loadingContext';
import ToastContext from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import { ad_find, tag_find } from '../../api/api';
import { Button, ConfigProvider, Input, Space, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import TagAdd from '../../components/tagManager/TagAdd';
import TagEdit from '../../components/tagManager/TagEdit';
import TagDelete from '../../components/tagManager/TagDelete';
import AdAdd from '../../components/adManager/AdAdd';
import TableFilter from '../../UI/TableFilter';
import useTableSize from '../../helper/useTableSize';


const AdList = () => {

  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast ,isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()  

  const [adListData, setAdListData] = useState([])
  const [showAdAdd, setShowAdAdd] = useState(false)
  const tableScrollY = useTableSize(60, 55)

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`搜寻 ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            搜寻
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重设
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            筛选
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            关闭
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if(record[dataIndex])
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    },      
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      text
  });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,      
      ...getColumnSearchProps('id'),
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      width: 300,      
      ...getColumnSearchProps('name'),
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },
    {
      title: '档案连结',
      dataIndex: 'file_url',
      key: 'file_url',
      width: 300,      
      ...getColumnSearchProps('file_url'),      
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },
    {
      title: '備註',
      dataIndex: 'remark_user',
      key: 'remark_user',
      width: 200,      
      ...getColumnSearchProps('remark_user'),      
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },
  ]

  const defaultCheckedList = columns.map((item) => item.key);

  const columnOptions = columns.map(item => ({label: item.title, key: item.key, value: item.key}))

  const [selectColumn, setSelectColumn] = useState(defaultCheckedList)
  const [filterColumn, setFilterColumn] = useState([])


  useEffect(() => {
    const newColumn = columns.map((item) => ({
      ...item,
      hidden: !selectColumn.includes(item.key),
    }));
    setFilterColumn(newColumn)
  }, [selectColumn])  

  useEffect(() => {
    const fetchTag = async() => {     

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await ad_find();       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setAdListData(listResponse)                  
        } else {
          showToast("error", "" + status, statusText)
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    fetchTag()
  }, [isRefresh])

 

  return (
    <>
      <div className='w-full'>
        <div className='flex flex-row py-2 w-full max-xl:flex-wrap max-xl:flex-col max-xl:items-center max-xl:space-y-1'>
          <Button type="primary" onClick={() => setShowAdAdd(true)}>
            新增
          </Button>
          <TableFilter selectColumn={selectColumn} setSelectColumn={setSelectColumn} columnOptions={columnOptions}>            
          </TableFilter>     
        </div>    
        <Table rowKey="id" style={{maxWidth: "100%"}} dataSource={adListData} columns={filterColumn} scroll={{x:"max-content", y:tableScrollY, scrollToFirstRowOnChange: true}} />        
      </div>    

      {showAdAdd && <AdAdd showAdAdd={showAdAdd} setShowAdAdd={setShowAdAdd}/>}
    </>
    
  );
};

export default AdList;