import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { tag_manage } from '../../api/api';
import ToastContext from '../../context/ToastContext';
import LoadingContext from '../../context/loadingContext';
import { useLocation } from 'react-router-dom';

const TagDelete = (props) => {

  const {showTagDelete, setShowTagDelete, tableRowData} = props
  const {showToast, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()

  const formik = useFormik({
    initialValues: {
      name: tableRowData.team_name
    },

   validate: (data) => {
      let errors = {};      
     
      if (!data.name) {								
        errors.name = '必填';
      }     

      return errors;
    },
    onSubmit: async(data) => {  
      
      const filterData = {     
        tag: tableRowData.id,   
        action: "9"    
      };    

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await tag_manage(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setIsRefresh(prev => !prev)                     
          showToast("success", "" + status, statusText)         
          setShowTagDelete(false)  
        } else {
          showToast("error", "" + status, statusText)
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }   
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Modal
          title="删除标签"
          centered
          open={showTagDelete}
          onCancel={() => setShowTagDelete(false)}
          footer={[                        
            <Button key="1" onClick={() => setShowTagDelete(false)}>取消</Button>,
            <Button key="2" type="primary" onClick={formik.handleSubmit} loading={isLoading}>送出</Button>
          ]}
          width={500}
          
        >
          <div className='flex flex-col'>
            <div>确定要删除标签吗? </div>
            <div>
              <span>标签ID: </span>{tableRowData.id}
            </div>
            <div>
              <span>标签名称: </span>{tableRowData.team_name}
            </div>
            
          </div>          
        </Modal>
      </form>
    </div>
  );
};

export default TagDelete;