import { Button, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import { ad_add, ad_find, tag_manage } from '../../api/api';
import ToastContext from '../../context/ToastContext';
import LoadingContext from '../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import Input from 'antd/es/input/Input';

const AdAdd = (props) => {

  const {showAdAdd, setShowAdAdd} = props
  const {showToast, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()  

  const formik = useFormik({
    initialValues: {
      files: "",
      remark_user: ""
    },

   validate: (data) => {
      let errors = {};      
     
      if (data.files.length <= 0) {				
        errors.files = '必填';
      } 

      return errors;
    },
    onSubmit: async(data, {resetForm}) => {  
      
      const filterData = {     
        files: data.files,
        remark_user: data.remark_user
      };    
  
      console.log("data.files: ", data.files);

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await ad_add(filterData);       
        
        console.log("listResponse: ", listResponse)
  
        if (status === 200) {                     
          setIsRefresh(prev => !prev)
          setShowAdAdd(false)
          showToast("success", "" + status, statusText)     
          resetForm()
        } else {
          showToast("error", "" + status, statusText) 
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    
  })
  
  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    
  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error text-red-500">{formik.errors[name]}</small> : <small className="p-error text-red-500">&nbsp;</small>;
  };	   

  const handleMultipleChange = (event) => {
    console.log("event: ", event);
    formik.setFieldValue("files", event.target.files[0])    
  }

  console.log("formik.values.files: ", formik.values.files);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Modal
          title="新增广告"
          centered
          open={showAdAdd}
          onCancel={() => setShowAdAdd(false)}
          footer={[                        
            <Button key="1" onClick={() => setShowAdAdd(false)}>取消</Button>,
            <Button key="2" type="primary" onClick={formik.handleSubmit}>送出</Button>
          ]}
          width={500}          
        >
        <div className='flex flex-col space-y-2'>
          <label htmlFor="name">广告档案上传</label>
          <input name="files" type="file" onChange={handleMultipleChange} accept='image/png, image/jpeg, image/jpg, image/gif' />   
          <small className='text-gray-400'>可接受的档案类型jpg, jpge, png, gif</small>
          {getFormErrorMessage("files")}  

          <label htmlFor="remark_user">备注</label>
          <Input type="text" name="remark_user" value={formik.values.remark_user} onChange={formik.handleChange} />
          {/* <button type="submit">Upload</button> */}
        </div>
                
        </Modal>
      </form>
    </div>
  );
};

export default AdAdd;