import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { video_title_edit } from '../../api/api';
import ToastContext from '../../context/ToastContext';
import LoadingContext from '../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import Input from 'antd/es/input/Input';

const VideoEdit = (props) => {

  const {showVideoEdit, setShowVideoEdit, tableRowData} = props
  const {showToast, setIsRefresh} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const location = useLocation()

  const formik = useFormik({
    initialValues: {
      name: tableRowData.影片標題
    },

   validate: (data) => {
      let errors = {};      
     
      if (!data.name) {								
        errors.name = '必填';
      }     

      return errors;
    },
    onSubmit: async(data) => {  
      
      const filterData = {     
        video_id: tableRowData.影片id,   
        new_title: data.name    
      };    

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await video_title_edit(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setIsRefresh(prev => !prev)                     
          showToast("success", "" + status, statusText)         
          setShowVideoEdit(false)  
        } else {
          showToast("error", "" + status, statusText)
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }   
  })

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Modal
          title="编辑影片"
          centered
          open={showVideoEdit}
          onCancel={() => setShowVideoEdit(false)}
          footer={[            
            <Button key="1" onClick={() => setShowVideoEdit(false)}>取消</Button>,
            <Button key="2" type="primary" onClick={formik.handleSubmit}>送出</Button>    
          ]}
          width={500}
        >
          <div className='flex flex-col'>
            <label htmlFor="name">影片标题</label>
            <Input
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}             
            />          
          </div>          
        </Modal>
      </form>
    </div>
  );
};

export default VideoEdit;