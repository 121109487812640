import React, { useContext, useEffect, useState } from 'react';
import MenuContext from '../context/MenuContext';
import { useLocation } from 'react-router-dom';
import { Breadcrumb } from "antd";
import { HomeOutlined } from '@ant-design/icons';

const BreadcrumbUI = () => {

  const {menuData} = useContext(MenuContext)
  const [breadItems, setBreadItems] = useState([])
  const location = useLocation()
  const path = location.pathname  

  useEffect(() => {
    const newBreadItems = []

    menuData?.map(row => {      
      if(row.url !== "/home"){
        if(path.includes(row.url)) {
          if(row.items) {
            newBreadItems.push({ title: <>{row.icon} <span>{row.label}</span></>} )
            row.items.map(item => {
              if(path === item.url) {
                newBreadItems.push({ title: <>{item.icon} <span>{item.label}</span></>, href: item.url})
              }
            })
          }else {
            newBreadItems.push({ title: <>{row.icon} <span>{row.label}</span></>, href: row.url} )
          }                    
        }
      }
     
    })
    setBreadItems(newBreadItems)
  },[path])

  return (    
    <div className='py-2 max-xl:hidden'>
       <Breadcrumb
        separator=">"
        items={breadItems}
      />  
    </div>  
   
    
  );
};

export default BreadcrumbUI;