import React, { useContext, useEffect, useState } from 'react';
import telegramImg from '../../images/telegram.png'
import { Button } from 'antd';
import GetLink from '../../components/getRobot/GetLink';

const GetRobot = () => {
  
  const [showGetLink, setShowGetLink] = useState(false)

  return (
    <>
      <div className='flex flex-col w-full h-full justify-center items-center space-y-4 '>
        <div className='flex flex-row items-center space-x-4'>
          <img src={telegramImg} className='w-16 h-16' />
          <p>Telegram Bot</p>
        </div>
        <Button type="primary" onClick={() => setShowGetLink(true)}>取得机器人连结</Button>
      </div>

      {showGetLink && <GetLink showGetLink={showGetLink} setShowGetLink={setShowGetLink}/>}
    </>
    
  );
};

export default GetRobot;