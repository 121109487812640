import React from 'react';
import Menu from './layouts/Menu';
import Navbar from './layouts/Navbar';
import { Outlet } from 'react-router-dom';
import BreadcrumbUI from './UI/BreadcrumbUI';

const Main = () => {
  return (
    <div className='flex flex-row w-[100%] h-[100dvh]'>
      <Menu/>      
      <div className='flex flex-col min-xl:w-[calc(100%-12rem)] max-xl:w-full h-full'>        
        <Navbar/>  
        <div className='w-full h-[calc(100%-3.5rem)] min-xl:p-4 bg-gray-200'>
          <div className='w-full h-full'>
            <BreadcrumbUI/>
            <div className='min-xl:p-2 bg-white shadow-md min-xl:h-[calc(100%-3rem)] max-xl:h-full overflow-auto'>
              <Outlet/>
            </div>
          </div>
          
        </div>      
      </div>
    </div>
  );
};

export default Main;