import { AppstoreAddOutlined, CalendarOutlined, CloudUploadOutlined, HomeOutlined, RobotOutlined, TagOutlined, VideoCameraAddOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const MenuContext = React.createContext();

export const MenuProvider = (props) => {   
    const location = useLocation()
    const navigate = useNavigate()

    const [showMenu, setShowMenu] = useState(false);
    // const [showMenuList, setShowMenuList] = useState([])    
    const [menuData, setMenuData] = useState(
      [
        {
          label: "首页",
          icon: <HomeOutlined />,
          active: false,
          url: "/"        
        },
        {
          label: "TG机器人",
          icon: <RobotOutlined />,
          active: false,
          url: "/getRobot"        
        },
        {
          label: "标签管理",
          icon: <TagOutlined />,
          active: false,
          url: "/tagManager",
          items: [
            {
              label: "标签列表",
              url: "/tagManager/tagList"
            }
          ]
        },
        {
          label: "影片管理",
          icon: <VideoCameraAddOutlined />,
          active: false,
          url: "/videoManager",
          items: [
            {
              label: "影片列表",
              url: "/videoManager/videoList"
            }
          ]
        },
        {
          label: "广告管理",
          icon: <AppstoreAddOutlined />,
          active: false,
          url: "/adManager",
          items: [
            {
              label: "广告列表",
              url: "/adManager/adList"
            }
          ]
        },
        {
          label: "排程管理",
          icon: <CalendarOutlined />,
          active: false,
          url: "/scheduleManager",
          items: [
            {
              label: "广告绑定设定",
              url: "/scheduleManager/adSetting"
            },          
            {
              label: "发送影片设定",
              url: "/scheduleManager/sendVideoSetting"
            },
            {
              label: "发送状态列表",
              url: "/scheduleManager/sendVideoList"
            }
          ]
        }  
      ]
    )

    const value = {
        menuData,
        setMenuData,
        showMenu,        
        setShowMenu,
        
        // showMenuList,
        // setShowMenuList
    };

    return <MenuContext.Provider value={value}>{props.children}</MenuContext.Provider>;
};

export default MenuContext
