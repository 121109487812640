
import { notification } from 'antd';
import React, { createContext, useRef, useState } from 'react';

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => { 
  const [isRefresh, setIsRefresh] = useState(false)  
  const [api, contextHolder] = notification.useNotification();

  const showToast = (type, title, msg) => {
    api[type]({
      message: title,
      description: msg,       
      pauseOnHover: true,
      showProgress: true,
      placement: "bottomRight",
      duration: 8,
    });
  }

  return (
    <ToastContext.Provider value={{showToast, isRefresh, setIsRefresh}}>
      {contextHolder}
      {children}
    </ToastContext.Provider>
  );
};

export default ToastContext