import { Select } from 'antd';
import React from 'react';

const TableFilter = (props) => {

  const {showSelectColumn = true, columnOptions, selectColumn, setSelectColumn} = props 

  return (
    <div className='ml-auto flex flex-row space-x-2 max-xl:flex-col max-xl:w-full max-xl:flex-wrap max-xl:items-center max-xl:space-y-1'>
      {showSelectColumn && 
        <Select
          mode="multiple"              
          style={{ width: "250px", maxWidth: "250px"}}
          placeholder="选择栏位"             
          onChange={setSelectColumn}
          options={columnOptions}              
          value={selectColumn}
          maxTagCount='responsive'
        />
      }
      {props.children}
    </div>
  );
};

export default TableFilter;