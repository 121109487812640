import React, { useContext, useEffect, useRef, useState } from 'react';
import { send_list, video_id_find } from '../../api/api';
import LoadingContext from '../../context/loadingContext';
import ToastContext from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import { Button, ConfigProvider, Input, Select, Space, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import TableFilter from '../../UI/TableFilter';
import useTableSize from '../../helper/useTableSize';

const SendVideoList = () => {

  const {setIsLoading} = useContext(LoadingContext)
  const {showToast ,isRefresh} = useContext(ToastContext)
  const location = useLocation()
  const [sendVideoListData, setSendVideoListData] = useState([])
  const [tgVideoCountList, setTgVideoCountList] = useState([])

  const tableScrollY = useTableSize(60, 60)

  console.log(sendVideoListData);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`搜寻 ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            搜寻
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重设
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            筛选
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            关闭
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if(record[dataIndex])
      return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      text
  });
  
  const columns = [   

    {      
      title: 'ID',
      dataIndex: 'id',
      key: 'ID',
      width: 80,    
      sorter: (a, b) => a.id - b.id,
      sortDirections: ['descend', 'ascend'],       
      ...getColumnSearchProps('id'),      
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },

    {
      title: '设定发送时间',
      dataIndex: '設置發送時間',
      key: '设定发送时间',
      width: 100,    
      sorter: (a, b) => new Date(a.設置發送時間) - new Date(b.設置發送時間),
      sortDirections: ['descend', 'ascend'],       
      ...getColumnSearchProps('設置發送時間'),      
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },

    {
      title: 'TG名称',
      dataIndex: 'TG名稱',
      key: 'TG名称',
      width: 100,      
      ...getColumnSearchProps('TG名稱'),     
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },   
    {
      title: '影片连结',
      dataIndex: '影片網址',
      key: '影片连结',
      width: 300,      
      ...getColumnSearchProps('影片網址'),      
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          {text}
        </div>
      ),
    },    
    
    {
      title: '发送状态',
      dataIndex: '發送狀態',
      key: '发送状态',
      width: 100,     
      ...getColumnSearchProps('發送狀態'),           
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word' }}>
          <p className={`${text === "已發送" ? "text-green-500 bg-green-100" : "text-red-500 bg-red-100"} w-16 text-center rounded-md`}>{text}</p>
        </div>
      ),
    },
    
  ];

  const defaultCheckedList = columns.map((item) => item.key);

  const columnOptions = columns.map(item => ({label: item.title, key: item.key, value: item.key}))

  const [selectColumn, setSelectColumn] = useState(defaultCheckedList)
  const [filterColumn, setFilterColumn] = useState([])


  useEffect(() => {
    const newColumn = columns.map((item) => ({
      ...item,
      hidden: !selectColumn.includes(item.key),
    }));
    setFilterColumn(newColumn)
  }, [selectColumn])  
    
  
  useEffect(() => {
    const fetchVideo = async() => {           
  
      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await send_list();       
        
        console.log("listResponse: ", listResponse)
  
        if (status === 200) {   
          //資料扁平化
          const result = [];
          const _tgVideoCountList = []
          for (const tg_name in listResponse) {
            
              if (listResponse.hasOwnProperty(tg_name)) {
                  _tgVideoCountList.push({"TG名稱": tg_name, "影片數量": listResponse[tg_name].影片數量})
                  const videos = listResponse[tg_name].影片列表;
                  Array.isArray(videos) && videos.map((item, index) => {result.push({"TG名稱": tg_name, ...item})})                 
              }
          }
          setTgVideoCountList(_tgVideoCountList)
          setSendVideoListData(result) 
        } else {
          showToast("error", "" + status, statusText)
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
  
    fetchVideo()
  }, [isRefresh])

 

  return (
    <>
      <div className='w-full flex flex-col'>
        <small className='text-gray-400'>已设定发送的影片状态列表</small>
        <div>
          <div className='flex flex-row py-2 w-full max-xl:flex-wrap max-xl:flex-col max-xl:items-center'>                
            <TableFilter selectColumn={selectColumn} setSelectColumn={setSelectColumn} columnOptions={columnOptions}></TableFilter> 
          </div>       
          <Table rowKey="id" style={{maxWidth: "100%"}} dataSource={sendVideoListData} columns={filterColumn} scroll={{x:"max-content", y: tableScrollY, scrollToFirstRowOnChange: true}} />
        </div>

        {/* <div className='flex flex-wrap justify-center'>
          {[...tgVideoCountList, ...tgVideoCountList, ...tgVideoCountList, ...tgVideoCountList ,...tgVideoCountList].map((item) => {
            return (
              <div className='flex flex-col justify-center items-center min-xl:w-36 min-xl:h-36 max-2xl:w-20 max-2xl:h-20 shadow-md p-2 m-4 max-2xl:text-sm hover:border-yellow-500 hover:border' onClick={() => {setSearchText(item.TG名稱); setSearchedColumn("TG名稱");}}>
                <p className='flex basis-4/5 justify-center items-center text-center'>{item.TG名稱}</p>
                <p className='basis-1/5 justify-center items-center text-center font-bold text-blue-400'>{item.影片數量}</p>                    
              </div>
            )
          })}
        </div> */}
        
      </div>     
    </>
  );
};

export default SendVideoList;