import React, { useContext } from 'react';
import MenuContext from '../context/MenuContext';
import { MenuOutlined } from '@ant-design/icons';
import boboImg from "../images/bobo.png"

const Navbar = () => {

  const {showMenu, setShowMenu} = useContext(MenuContext)

  return (
    <div className='sticky flex flex-row items-center px-2 w-full h-14 bg-white shadow-md'>     
      <div className='min-xl:hidden flex justify-center items-center pt-8 w-12 h-12 p-2 border rounded-full overflow-hidden text-2xl' onClick={() => setShowMenu(prev => !prev)}>
        <img className='max-w-24 max-h-24' src={boboImg}/>
      </div>
    </div>
  );
};

export default Navbar;