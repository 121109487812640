import { Button, Modal, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { tag_find, tag_manage, video_add } from '../../api/api';
import ToastContext from '../../context/ToastContext';
import LoadingContext from '../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import Input from 'antd/es/input/Input';
import { PlusOutlined } from '@ant-design/icons';

const VideoAdd = (props) => {

  const {showVideoAdd, setShowVideoAdd} = props
  const {showToast, isRefresh, setIsRefresh} = useContext(ToastContext)
  const {setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const [tagOption, setTagOption] = useState([])
  const [inputNumber, setInputNumber] = useState(["1"])

  const formik = useFormik({
    initialValues: {
      tag: "",
      video1: "",
      image1: "",
      title1: "",
      video2: "",
      image2: "",
      title2: "",
      video3: "",
      image3: "",
      title3: "",
      video4: "",
      image4: "",
      title4: "",
      video5: "",
      image5: "",
      title5: "",      
    },

   validate: (data) => {
      let errors = {};      
     
      if (data.tag.length <= 0) {								
        errors.name = '必填';
      } 

      return errors;
    },
    onSubmit: async(data) => {       

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await video_add(data);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setIsRefresh(prev => !prev)
          setShowVideoAdd(false)                     
          showToast("success", "" + status, statusText)         
        } else {
          showToast("error", "" + status, statusText) 
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }   
  })
  
  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    
  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error text-red-500">{formik.errors[name]}</small> : <small className="p-error text-red-500">&nbsp;</small>;
  };	  
  
  const handleTagChange = (value) => {
    formik.setFieldValue("tag", value)
  }

  const handleVideoChange = (value, index) => {
    formik.setFieldValue(`video${index}`, value)
  }

  const handleImageChange = (value, index) => {
    formik.setFieldValue(`image${index}`, value)
  }

  const handleTitleChange = (value, index) => {
    formik.setFieldValue(`title${index}`, value)
  }

  const plusVideoFormHandler = () => {
    if(inputNumber.length < 5) {
      let newArray = [...inputNumber]
      let currentNumber = inputNumber.length + 1 + ""
      newArray.push(currentNumber)
      setInputNumber(newArray)    
    }
  }
   

  useEffect(() => {
    const fetchTag = async() => {     

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await tag_find();       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setTagOption(listResponse.map(item => ({label: item.team_name, value: item.id})))                  
        } else {
          showToast("error", "" + status, statusText)
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    fetchTag()
  }, [isRefresh])

  console.log(formik.values);
  console.log("inputNumber: ", inputNumber);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Modal
          title="新增影片"
          centered
          open={showVideoAdd}
          onCancel={() => setShowVideoAdd(false)}
          footer={[                        
            <Button key="1" onClick={() => setShowVideoAdd(false)}>取消</Button>,
            <Button key="2" type="primary" onClick={formik.handleSubmit}>送出</Button>
          ]}
          width={1200}         
        >
          
          <div className='flex flex-row min-xl:items-center max-xl:flex-col flex-wrap w-full'>
            <div className='w-[10%] max-xl:w-full flex flex-col' htmlFor="video_ids">标签:<small className='text-gray-400'>选择影片的标签(可复选)</small></div>          
            <Select               
              mode="multiple"
              allowClear
              className='w-[90%] max-xl:w-full'
              placeholder="请选择标签"                
              onChange={handleTagChange}
              options={tagOption}
              
            />                         
            {getFormErrorMessage("video_ids")}
          </div>
                     

          {inputNumber.map(row => {
            return (
              <div key={row} className='flex flex-col'>
                <p>影片{row}</p>
                <div className='flex flex-row min-xl:items-center max-xl:flex-col flex-wrap w-full p-2'>
                  <div className='w-[10%] max-xl:w-full' htmlFor="video_ids">影片:</div>          
                  <Input             
                    allowClear
                    className='w-[90%] max-xl:w-full'
                    placeholder="请输入影片连结"                
                    onChange={(e) => {handleVideoChange(e.target.value, row)}}                      
                  />             
                </div>
                <div className='flex flex-row min-xl:items-center max-xl:flex-col flex-wrap w-full p-2'>
                  <div className='w-[10%] max-xl:w-full flex flex-col' htmlFor="video_ids">图标:<small className='text-gray-400'>影片中显示图标</small></div>          
                  <Input             
                    allowClear
                    className='w-[90%] max-xl:w-full'
                    placeholder="请输入图片连结"                
                    onChange={(e) => {handleImageChange(e.target.value, row)}}                      
                  />             
                </div>
                <div className='flex flex-row min-xl:items-center max-xl:flex-col flex-wrap w-full p-2'>
                  <div className='w-[10%] max-xl:w-full' htmlFor="video_ids">标题:</div>          
                  <Input             
                    allowClear
                    className='w-[90%] max-xl:w-full'
                    placeholder="请输入影片标题"                
                    onChange={(e) => {handleTitleChange(e.target.value, row)}}                      
                  />             
                </div>
              </div>
            )
          })}   
          {inputNumber.length < 5 &&           
            <Button type="primary" icon={<PlusOutlined />} onClick={plusVideoFormHandler} size='small' />
          }    
          
          
          
        </Modal>

        
      </form>
    </div>
  );
};

export default VideoAdd;