import React, { useContext, useEffect, useState } from 'react';
import LoadingContext from '../../context/loadingContext';
import ToastContext from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import { ad_assign, ad_find, video_id_find } from '../../api/api';
import { Button, Select } from 'antd';
import { useFormik } from 'formik';

const AdSetting = () => {

  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast ,isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()  

  const [videoOptions, setVideoOptions] = useState()
  const [adOptions, setAdOptions] = useState([])  

  const formik = useFormik({
    initialValues: {
      video_ids: [],
      ad_start: "",
      ad_middle: "",
      ad_end: ""
    },

   validate: (data) => {
      let errors = {};      
     
      if (data.video_ids.length <= 0) {								
        errors.video_ids = '必填';
      }     

      return errors;
    },
    onSubmit: async(data, {resetForm}) => {  
      
      const filterData = {     
        video_ids: data.video_ids,
        ad_start: data.ad_start ?? "",
        ad_middle: data.ad_middle ?? "",
        ad_end: data.ad_end ?? ""
      };    

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await ad_assign(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setIsRefresh(prev => !prev)                     
          showToast("success", "" + status, statusText)   
          resetForm()       
        } else {
          showToast("error", "" + status, statusText) 
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }   
  })

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    
  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error text-red-500">{formik.errors[name]}</small> : <small className="p-error text-red-500">&nbsp;</small>;
  };	  

  console.log(formik.values);

  const handleSelectChange = (value) => {
    formik.setFieldValue('video_ids', value);
  };

  const handleAdStartChange = (value) => {
    formik.setFieldValue('ad_start', value);
  };

  const handleAdMiddleChange = (value) => {
    formik.setFieldValue('ad_middle', value);
  };

  const handleAdEndChange = (value) => {
    formik.setFieldValue('ad_end', value);
  };

  useEffect(() => {
    const fetchVideo = async() => {     

      const filterData = {
        state_type: "1"
      }

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await video_id_find(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {      
          let optionData = [];
          for (const username in listResponse) {
            let optionGroup = {label: <span>{username}</span>, title: username, options: []}
            if (listResponse.hasOwnProperty(username)) {
                let optionItems = []
                const videos = listResponse[username];
                Array.isArray(videos) && videos.map(video => {
                  optionItems.push({     
                    key: video.影片id,                         
                    label: <span>{video.影片標題}</span>,
                    value: video.影片id + ""
                  });
                })             
                
                optionGroup.options = optionItems
            }
            optionData.push(optionGroup)
          }
          console.log("optionData: ", optionData);
          setVideoOptions(optionData)          
                   
        } else {
          showToast("error", "" + status, statusText)
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    const fetchTag = async() => {     

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await ad_find();       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setAdOptions(listResponse.map(item => ({key: item.id, title: item.name, value: item.name})))                  
        } else {
          showToast("error", "" + status, statusText)
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    
    fetchVideo()
    fetchTag()
  }, [isRefresh])

  console.log("videoOptions: ",videoOptions);

  return (
    <>
      <div className='w-full'>
        <small className='text-gray-400'>影片要绑定的广告</small>

        <form className='flex flex-col p-12 max-xl:p-4 space-y-8' onSubmit={formik.handleSubmit}>
          
          <div className='flex flex-row max-xl:flex-col flex-wrap w-full'>
            <div className='w-[10%] max-xl:w-full' htmlFor="video_ids">影片:</div>          
            <Select               
              mode="multiple"
              allowClear
              className='w-[90%] max-xl:w-full'
              placeholder="请选择影片"                
              onChange={handleSelectChange}
              options={videoOptions}
              value={formik.values.video_ids}
            />                         
            {getFormErrorMessage("video_ids")}
          </div>  

          <div className='flex flex-row max-xl:flex-col flex-wrap w-full'>
            <div className='w-[10%] max-xl:w-full' htmlFor="video_ids">片头广告</div>            
              <Select                
                showSearch
                className='w-[50%] max-xl:w-full'
                placeholder="请选择片头广告"
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={adOptions}
                allowClear                
                onChange={handleAdStartChange}
                value={formik.values.ad_start}
              />                                 
          </div>

          <div className='flex flex-row max-xl:flex-col flex-wrap w-full'>
            <div className='w-[10%] max-xl:w-full' htmlFor="video_ids">全程置底广告</div>            
              <Select                
                showSearch
                className='w-[50%] max-xl:w-full'
                placeholder="请选择全程置底广告"
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={adOptions}
                allowClear                
                onChange={handleAdMiddleChange}
                value={formik.values.ad_middle}
              />                                 
          </div>

          <div className='flex flex-row max-xl:flex-col flex-wrap w-full'>
            <div className='w-[10%] max-xl:w-full' htmlFor="video_ids">片尾广告</div>            
              <Select                
                showSearch
                className='w-[50%] max-xl:w-full'
                placeholder="请选择片尾广告"
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={adOptions}
                allowClear                
                onChange={handleAdEndChange}
                value={formik.values.ad_end}
              />                                 
          </div>  
         
          <div className='flex justify-center'>
            <Button type="primary" onClick={formik.handleSubmit}>送出</Button>
          </div>
        </form>
        
      </div>      
    </>
    
  );
};

export default AdSetting;