import React, { useContext, useEffect, useState } from 'react';
import LoadingContext from '../../context/loadingContext';
import ToastContext from '../../context/ToastContext';
import { useLocation } from 'react-router-dom';
import { ad_assign, ad_find, send_permissions, send_set_time, video_id_find } from '../../api/api';
import { Button, Calendar, Select, Space, theme, TimePicker } from 'antd';
import { useFormik } from 'formik';

const SendVideoSetting = () => {

  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const {showToast ,isRefresh, setIsRefresh} = useContext(ToastContext)
  const location = useLocation()  
  const [videoListData, setVideoListData] = useState([])
  const [videoNoAdListData, setVideoNoAdListData] = useState([])
  const [tgListData, setTgListData] = useState([])

  const { token } = theme.useToken();
  const calendarWrapperStyle = {
    width: 300,
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };

  const onPanelChange = (value, mode) => {
    console.log(value.format('YYYY-MM-DD'), mode);
  };

  const formik = useFormik({
    initialValues: {
      video_ids: [],
      tg_id: "",      
      dateInput: "",
      timeInput: "",

    },

   validate: (data) => {
      let errors = {};      
     
      if (data.video_ids.length <= 0) {								
        errors.video_ids = '必填';
      }
      
      if (!data.tg_id) {								
        errors.tg_id = '必填';
      } 

      if (!data.dateInput) {								
        errors.dateInput = '必填';
      } 

      if (!data.timeInput) {								
        errors.timeInput = '必填';
      } 

      return errors;
    },
    onSubmit: async(data, {resetForm}) => {  
      const send_time = data.dateInput && data.timeInput ? `${data.dateInput.format("YYYY-MM-DD")} ${data.timeInput.format("HH:mm:ss")}` : ""
      
      const filterData = {     
        values: data.video_ids, 
        tg_id: data.tg_id,
        send_time            
      };    

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await send_set_time(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {    
                    
          setIsRefresh(prev => !prev)                     
          showToast("success", "" + status, <div><p>{statusText}</p><p>{listResponse.note ?? null}</p></div>)       
          resetForm()             
        } else {
          showToast("error", "" + status, statusText) 
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }   
  }) 

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);
    
  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error text-red-500">{formik.errors[name]}</small> : <small className="p-error text-red-500">&nbsp;</small>;
  };	   

  const handleSelectVideoChange = (value) => {
    formik.setFieldValue('video_ids', value);
  };

  const handleSelectTgChange = (value) => {
    formik.setFieldValue('tg_id', value);
  };

  const handleSelectDateChange = (value) => {
    formik.setFieldValue('dateInput', value);
  };

  const handleSelectTimeChange = (value) => {
    formik.setFieldValue('timeInput', value);
  };

  useEffect(() => {
    const fetchVideo = async(state_type) => {     

      const filterData = {
        state_type: state_type
      }

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await video_id_find(filterData);       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {      
          let optionData = [];
          for (const username in listResponse) {
            let optionGroup = {label: <span>{username}</span>, title: username, options: []}
            if (listResponse.hasOwnProperty(username)) {
                let optionItems = []
                const videos = listResponse[username];
                Array.isArray(videos) && videos.map(video => {
                  if(state_type === "1")
                  {
                    optionItems.push({     
                      key: video.影片id,                         
                      label: <span>{video.影片標題}</span>,
                      value: video.影片id + "",
                      noAd: true
                    });
                  }else if(state_type === "2") {
                    optionItems.push({     
                      key: video.影片id,                         
                      label: <span>{video.影片標題}</span>,
                      value: video.影片id + "",                      
                    });
                  }                  
                })                 
                optionGroup.options = optionItems
            }
            optionData.push(optionGroup)
          }
          console.log("optionData: ", optionData);
          if(state_type === "1")
          {
            setVideoNoAdListData(optionData)
          }else if(state_type === "2")
          {
            setVideoListData(optionData)
          }
                   
        } else {
          showToast("error", "" + status, statusText)
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }
    
    //獲取無廣告、有廣告的video
    fetchVideo("2")
   
    const fetchTg = async() => {     

      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await send_permissions();       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {  
          let newData = Array.isArray(listResponse) && listResponse.map(item => ({label: item.名稱, value: item.tg_id}))                   
          setTgListData(newData)                  
        } else {
          showToast("error", "" + status, statusText)
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    fetchTg()   
   
  }, [isRefresh])
  
  if(formik.values.dateInput && formik.values.timeInput) {
    console.log(formik.values.dateInput.format("YYYY-MM-DD") + " " + formik.values.timeInput.format("HH:mm:ss"));
  }

  console.log("formik.values: ", formik.values);

  return (
    <>
      <div className='w-full'>
        <small className='text-gray-400'>影片设定要发送日期/时间</small>
        <form className='flex flex-col p-12 max-xl:p-4 space-y-8' onSubmit={formik.handleSubmit}>
          
          <div className='flex flex-row min-xl:items-center max-xl:flex-col flex-wrap w-full '>
            <div className='w-[10%] max-xl:w-full'>影片:</div>          
            <Select               
              mode="multiple"
              allowClear
              className='w-[80%] max-xl:w-full'
              placeholder="请选择影片"                
              onChange={handleSelectVideoChange}
              options={videoListData}
              // optionRender={(option) => (
              //   <Space>
              //     {option.data.noAd &&                  
              //       <span aria-label={option.data.label} className='text-xs text-red-500'>
              //         无广告
              //       </span>
              //     }                  
              //     {option.data.label}
              //   </Space>
              // )}
              value={formik.values.video_ids}  
            />    
            {getFormErrorMessage("video_ids")}            
          </div>  
          
          <div className='flex flex-row min-xl:items-center max-xl:flex-col flex-wrap w-full'>
            <div className='w-[10%] max-xl:w-full'>TG帐号:</div>          
            <Select
              allowClear
              className='w-[30%] max-xl:w-full'
              placeholder="请选择TG帐号"                
              onChange={handleSelectTgChange}
              options={tgListData}           
              value={formik.values.tg_id}  
            />                 
            {getFormErrorMessage("tg_id")}        
          </div>  
          
          
          <div className='flex flex-row max-xl:flex-col flex-wrap w-full'>
            <div className='w-[10%] max-xl:w-full'>发送日期/时间</div>  
            <div className='flex flex-col space-y-2'>
              <div style={calendarWrapperStyle}>
                <Calendar value={formik.values.dateInput} onChange={handleSelectDateChange} fullscreen={false} />              
              </div>            
              {getFormErrorMessage("dateInput")}
              <div className='flex items-center'>
                <TimePicker value={formik.values.timeInput} onChange={handleSelectTimeChange} placeholder='请选择时间'/>
              </div>
              {getFormErrorMessage("timeInput")}
            </div>        
           
          </div> 

          <div className='flex justify-center'>
            <Button type="primary" htmlType="submit">送出</Button>
          </div>
        </form>
        
      </div>      
    </>
    
  );
};

export default SendVideoSetting;