import { Button, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import ToastContext from '../../context/ToastContext';
import LoadingContext from '../../context/loadingContext';
import { useLocation } from 'react-router-dom';
import { get_tg_invite_link } from '../../api/api';

const GetLink = (props) => {

  const {showGetLink, setShowGetLink} = props
  const {showToast, setIsRefresh} = useContext(ToastContext)
  const {isLoading, setIsLoading} = useContext(LoadingContext)
  const location = useLocation()
  const [botData, setBotData] = useState({})

  useEffect(() => {
    const fetchRobot = async() => {
     
      try {
        setIsLoading(true)
        const { listResponse, status, statusText } = await get_tg_invite_link();       
        
        console.log("listResponse: ", listResponse)

        if (status === 200) {                     
          setIsRefresh(prev => !prev)   
          setBotData(listResponse)                     
        } else {
          showToast("error", "" + status, statusText)
        }
      } catch (error) {        
        console.error(location.pathname, error);            
      } finally {
        setIsLoading(false)
      }
    }

    fetchRobot()
  }, [])

  return (
    <div>
      
        <Modal          
          centered
          open={showGetLink}
          onCancel={() => setShowGetLink(false)}
          footer={[  
            <div className='flex justify-center w-full'>
              <Button type="primary" onClick={() => setShowGetLink(false)}>ok</Button>
            </div>                      
          ]}
          width={800}
          
        >              
          <div className='flex flex-col p-4 space-y-4'>
            <div className='flex flex-row'>
              <div className='basis-1/4 font-bold'>个人开通权限流程:</div>      
              <div className='basis-3/4'>{botData.個人開通權限流程}</div>        
            </div>

            <div className='flex flex-row'>
              <div className='basis-1/4 font-bold'>群组开通权限流程:</div>      
              <div className='basis-3/4'>{botData.群組開通權限流程}</div>        
            </div>   

            <div className='flex flex-row'>
              <div className='basis-1/4 font-bold'>密码:</div>      
              <div className='basis-3/4'>{botData.密碼}</div>        
            </div>

            <div className='flex flex-row'>
              <div className='basis-1/4 font-bold'>密码有效期限:</div>      
              <div className='basis-3/4'>{botData.密碼有效期限}</div>        
            </div>

            <div className='flex flex-row'>
              <div className='basis-1/4 font-bold'>机器人邀请连结:</div>      
              <div className='basis-3/4 text-blue-500'>
                <a href={botData.機器人邀請連結}>{botData.機器人邀請連結}</a> 
              </div>        
            </div>               
           
          </div>          
        </Modal>
    </div>
  );
};

export default GetLink;