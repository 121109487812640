import { Route, Routes } from "react-router-dom";
import Main from "./Main";
import Home from "./components/Home";
import GetRobot from "./pages/getRobot/GetRobot";
import { LoadingProvider } from "./context/loadingContext";
import { ToastProvider } from "./context/ToastContext";
import Loading from "./UI/loading";
import TagList from "./pages/tagManager/TagList";
import { MenuProvider } from "./context/MenuContext";
import VideoList from "./pages/videoManager/VideoList";
import AdList from "./pages/adManger/AdList";
import { ConfigProvider } from "antd";
import TGList from "./pages/getRobot/TGList";
import AdSetting from "./pages/scheduleManager/AdSetting";
import SendVideoSetting from "./pages/scheduleManager/SendVideoSetting";
import SendVideoList from "./pages/scheduleManager/SendVideoList";

function App() {
  return (
    <>
      <MenuProvider>
        <LoadingProvider>  
          <ToastProvider>
            <ConfigProvider autoInsertSpaceInButton={false}>
              <Loading></Loading>
              <Routes>
                <Route path="/" element={<Main/>}>
                  <Route path="" element={<Home/>}/>
                  <Route path="/getRobot" element={<GetRobot/>}/>
                  <Route path="tagManager">
                    <Route path="tagList" element={<TagList/>}/>
                  </Route>
                  <Route path="/videoManager">
                    <Route path="videoList" element={<VideoList/>}/>
                  </Route>
                  <Route path="/adManager">
                    <Route path="adList" element={<AdList/>}/>
                  </Route>               
                  <Route path="/scheduleManager">
                    <Route path="adSetting" element={<AdSetting/>}/>
                    <Route path="sendVideoSetting" element={<SendVideoSetting/>}/>
                    <Route path="SendVideoList" element={<SendVideoList/>} />
                  </Route> 
                </Route>
              </Routes>
            </ConfigProvider>           
          </ToastProvider>   
        </LoadingProvider>  
      </MenuProvider>
    </>
    
  );
}

export default App;
